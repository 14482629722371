<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import InfoTable from "@/components/listings/Info-table";
import basicTable from "@/components/listings/basic-table";
import { loopDetails } from "@/util/mapfunction.js";
import { RouterLink } from "vue-router";
import MessageModal from "@/components/modals/message-modal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import LoadingSpinner from '@/components/Loading';
// import StatusBadge from '@/components/buttons/tenancy-status'

export default {
	components: {
		InfoTable,
		basicTable,
		MessageModal,
		ConfirmationModal,
		RouterLink,
		LoadingSpinner
		// StatusBadge
	},
	name: "tabs-details",
	created() {
		this.isLoading = true;
		this.getDetails();
		this.getRemark(this.addressPage);
	},
	data() {
		return {
			title: `Member View`,
			items: [
				{
					text: "Member",
				},
				{
					text: "view",
					active: true,
				},
			],

			//member basic info
			memberInfo: {
				// status: {
				// 	label: "Status",
				// 	value: "",
				// },
				first_name: {
					label: "First Name",
					value: "",
				},
				last_name: {
					label: "Last Name",
					value: "",
				},
				email: {
					label: "Email",
					value: "",
				},
				phone: {
					label: "Phone Number",
					value: "",
				},
				ic: {
					label: "NRIC No.",
					value: "",
				},
			},

			//compan info
			companyInfo: {
				company_name: {
					label: "Company Name",
					value: "",
				},
				company_reg: {
					label: "Company Registration No.",
					value: "",
				},
				company_address: {
					label: "Company Address",
					value: "",
				},
			},

			payout: {
				bank_name: {
					label: "Bank Name",
					value: "",
				},
				bank_acc: {
					label: "Bank Acc No.",
					value: "",
				},
				bank_receiver_name: {
					label: "Bank Receiver Name",
					value: "",
				},
			},


			memberAddress: [],
			componentKey: 0,
			// profileImg: '',

			//address
			//field 
			addressFields: [
				{ key: "id", "label": "ID" },
				{ key: "full_address", "label": "Address" },
				{ key: "default_shipping_address", "label": "Default Address" },
				{ key: "action", "label": "Action" },
			],
			remarkFields: [
				{ key: "remark", "label": "Description" },
				{ key: "action", "label": "Action By" },
			],

			//address data
			addressItems: [],
			addressPage: 1,
			addressPerpage: 10,
			addressLoading: true,
			addressTotal: 0,

			//Remark data
			remarkItems: [],
			remarkPage: 1,
			remarkPerpage: 10,
			remarkLoading: true,
			remarkTotal: 0,
			
			isLoading: false
		};
	},
	props: ["id"],

	methods: {
		async getDetails() {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/member/${this.id}`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Member's Info error:" + error.message);
				this.isLoading = false;
			});

			if (result && result.data["code"] === "success") {
				const apiCall = result.data.data.member;
				// this.profileImg =
				//   apiCall.profile_photo_path == null
				//     ? require("@/assets/images/users/user_icon.png")
				//     : apiCall.profile_photo_path;
				loopDetails(this.memberInfo, apiCall);
				loopDetails(this.companyInfo, apiCall);
				console.log('asdsad', this.payout)
				Object.keys(this.payout).forEach(element => {
					console.log('element', element)
					if (apiCall.payout_details === null) {
						this.payout[element].value = '-';
					} else {
						if (apiCall.payout_details[element]) {
							this.payout[element].value = apiCall.payout_details[element];
						} else {
							this.payout[element].value = '-';
						}
					}
				});


				if (apiCall.profile_photo_path == null) {
					this.$refs.uploadBtn.innerHTML = "Upload Image";
				}
				await this.getAddress(this.addressPage);
			}
		},

		async getAddress(page) {
			this.addressLoading = true;
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/member/all-address/${this.id}`, {
				headers: authHeader(),
				params: {
					"page": page,
					"length": this.addressPerpage,
				}
			}).catch(function (error) {
				this.addressLoading = false;
				alert("Member's address error:" + error.message);
				this.isLoading = false;
			});

			if (result.status && result.data.code === 'success') {
				const address = result.data.data;
				this.addressItems = address;
				this.addressTotal = result.data.totalRecords;
			} else if (result.data.code === 'not_found') {
				this.addressItems = [];
				this.addressTotal = 0;
			}
			this.isLoading = false;

			this.addressLoading = false;
		},

		// async uploadHandler() {
		//   this.uploadImg = this.$refs.profileUpload.files[0];
		//   if (this.uploadImg != undefined) {
		//     let formData = new FormData();
		//     formData.append("img", this.uploadImg);
		//     const result = await axios.post(
		//       `${process.env.VUE_APP_APIURL}/member/profile-img/${this.id}`,
		//       formData,
		//       {
		//         headers: {
		//           "Content-Type": "multipart/form-data",
		//           Authorization: authHeader()["Authorization"],
		//         },
		//       }
		//     );
		//     if (result.data.code === "success") {
		//       this.profileImg = process.env.VUE_APP_PROFILE_URL + '/'  + result.data.data.member.profile_photo_path;
		//       this.profileImg = result.data.data.member.profile_photo_path;
		//     } else {
		//       alert("Internal Error");
		//     }
		//   }
		// },
		// async deleteProfileImg() {
		//   const result = await axios.delete(
		//     `${process.env.VUE_APP_APIURL}/member/profile-img/${this.id}`,
		//     {
		//       headers: authHeader(),
		//     }
		//   );
		//   if (result.data.code === "success") {
		//     this.profileImg = require("@/assets/images/users/user_icon.png");
		//   } else {
		//     alert("Internal Error");
		//   }
		// },

		async changePage(page) {
			await this.getAddress(page);
		},

		async changeRemarkPage(page) {
			await this.getRemark(page);
		},

		async getRemark(page) {
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/member/history/${this.id}`, {
				headers: authHeader(),
				params: {
					"page": page,
					"length": this.remarkPerpage,
				}
			}).catch(function (error) {
				this.addressLoading = false;
				alert("Member's Remark error:" + error.message);
			});

			if (result.status && result.data.code === 'success') {
				const remark = result.data.data;
				this.remarkItems = remark.data;
				this.remarkTotal = result.data.totalRecords;
			} else if (result.data.code === 'not_found') {
				this.remarkItems = [];
				this.remarkTotal = 0;
			}
			this.remarkLoading = false;
		},
		deleteAddress(id) {
			const deleteCallback = async () => {
				let result = await axios.delete(`${process.env.VUE_APP_APIURL}/member/delete-address/${id}`, {
					headers: authHeader(),
				});

				this.$refs.confirmationModal.closeModal();
				if (result) {
					if (result.data.code === 'success') {
						this.$refs.messageModal.showModal('The record has been deleted');
						await this.getAddress(this.addressPage);
					} else if (result.data.code === 'permission_denied') {
						this.$refs.messageModal.showModal('Permission Denied');
					} else {
						this.$refs.messageModal.showModal('Internal Error');
					}
				}
			}
			this.$refs.confirmationModal.showModal(`Confirm delete amenity (ID: ${id})?`, deleteCallback);
		}
	},
	watch: {},
};
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<LoadingSpinner :isLoading="isLoading" />
		<ConfirmationModal ref="confirmationModal" />

		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-12">
						<router-link class="btn btn-orange mb-2"
							:to="{ name: 'Member Edit Form', params: { id: this.id } }"><i
								class="ri-pencil-line font-size-12 mr-2"></i>
							Edit Details
						</router-link>
					</div>
					<div class="col-12 d-flex justify-content-center">
						<!-- <div>
              <div class="profileImg">
                <img :src="profileImg" alt="profileImg" />
              </div>
              <div class="profileImg_btn">
                <div class="text-center">
                  <input
                    type="file"
                    name="uploadProfile"
                    id="uploadProfile"
                    ref="profileUpload"
                    accept="image/*"
                    @change="uploadHandler"
                    hidden
                  />
                  <button
                    class="btn btn-md btn-primary profileBtn"
                    @click="$refs.profileUpload.click()"
                    ref="uploadBtn"
                  >
                    Change Image
                  </button>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-md btn-danger profileBtn"
                    v-show="profileImg != require('@/assets/images/users/user_icon.png')"
                    @click="deleteProfileImg"
                  >
                    Delete Image
                  </button>
                </div>
              </div>
            </div> -->
					</div>

					<div class="col-12 col-md-6">
						<InfoTable :tableHeaderText="'Member ID: ' + this.id" :tableData="memberInfo">
							<!-- <template #status>
								<StatusBadge :status="parseInt(memberInfo.status.value)" />
							</template> -->
						</InfoTable>
					</div>

					<div class="col-12 col-sm-6">
						<InfoTable :tableHeaderText="'Company Info'" :tableData="companyInfo" />
					</div>

					<div class="col-12 col-sm-6">
						<InfoTable :tableHeaderText="'Payout Info'" :tableData="payout" />
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12 d-flex align-items-center mb-2">
						<h4 class="subTitle-font mb-0 mr-2">
							Saved Addresses:
						</h4>
						<router-link class="btn btn-orange btn-sm"
							:to="{ name: 'Member Add Address', params: { id: this.id }, query: { member: id } }">Add
							address</router-link>
					</div>
					<!-- <ListingTable
            ref="listingTable"
            :fields="fields"
            :setMemberView="setMemberView"
            :initialSetting="initialSetting"
            path=""
            listName="members"
          >
          </ListingTable> -->

					<div class="col-12">
						<basicTable :items="addressItems" :fields="addressFields" :perPage="addressPerpage"
							@page-click="changePage" :isBusy="addressLoading" :totalRows="addressTotal"
							:key="componentKey">
							<template v-slot:cell(action)="{ item }">
								<router-link class="mr-2 text-primary"
									:to="{ name: 'Member Edit Address', params: { id: item.id }, query: { member: id } }">
									<i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i>
								</router-link>
								<a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
									@click="deleteAddress(item.id)">
									<i class="ri-delete-bin-line font-size-18"></i>
								</a>
							</template>
							<template v-slot:cell(default_shipping_address)="row">
								{{ row.value == 1 ? 'Yes' : 'No' }}
							</template>
						</basicTable>
					</div>
				</div>

				<div class="row mt-3">
					<h4 class="subTitle-font col-12">Histories:</h4>

					<div class="col-12">
						<basicTable :items="remarkItems" :fields="remarkFields" :perPage="remarkPerpage"
							@page-click="changeRemarkPage" :isBusy="remarkLoading" :totalRows="remarkTotal">
						</basicTable>
					</div>

					<!-- <RemarkTable :path="`member/history/${this.id}`" :perPage="parseInt(10)" :fields="remarkField">
      
          </RemarkTable> -->
				</div>

				<div class="row">
					<div class="col-12 d-flex attachment__wrapper"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.profileImg {
	width: 200px;
	height: 200px;
	border: 1px solid black;
	margin-bottom: 10px;

	img {
		width: 100%;
		padding: 10px;
		height: 100%;
		object-fit: contain;
	}
}

.profileImg_btn {
	width: 200px;
}

.profileBtn {
	margin-bottom: 8px;
	width: 120px;
}
</style>
